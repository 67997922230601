<template>
  <div id="save-address-form">
    <h2 class="mb-2" :class="{ 'text-center title-address': textRight }">
      <template v-if="userData">
        {{ parentData ? "Edit" : "Add" }} address
      </template>
      <template v-else>
        Buyer Details
      </template>
    </h2>
    <form @submit.prevent="saveAddress">
      <div class="row">
        <!-- Email Address -->
        <div v-if="!userData" class="col-md-12">
          <MyInputFormText
            type="email"
            id="recipient-email"
            label="Email *"
            v-model="$v.email.$model"
            placeholder="Email"
            :classComponent="{
              'is-invalid-custom': $v.email.$error,
              'is-valid-custom': !$v.email.$invalid,
            }"
            :invalidMessage="[
              !$v.email.required ? 'Butuh email kamu nih' : null,
              !$v.email.namePattern ? 'Tolong diisi ya ;)' : null,
            ]"
          />
        </div>
        <!-- Full Name -->
        <div class="col-6">
          <MyInputFormText
            id="recipient-name"
            label="Recipient Name *"
            v-model="$v.full_name.$model"
            placeholder="Recipient Name"
            :classComponent="{
              'is-invalid-custom': $v.full_name.$error,
              'is-valid-custom': !$v.full_name.$invalid,
            }"
            :invalidMessage="[
              !$v.full_name.required ? 'Butuh nama lengkap kamu nih' : null,
              !$v.full_name.namePattern ? 'Masukin text yu!' : null,
            ]"
          />
        </div>
        <!-- Phone Number -->
        <div class="col-6">
          <MyInputFormText
            id="phone-number"
            label="Phone Number *"
            v-model="$v.phone.$model"
            :classComponent="{
              'is-invalid-custom': $v.phone.$error,
              'is-valid-custom': !$v.phone.$invalid,
            }"
            :invalidMessage="[
              !$v.phone.required ? 'Butuh nomor telepon kamu nih' : null,
              !$v.phone.phonePattern
                ? 'Nomor telepon kamu tidak valid :('
                : null,
              !$v.phone.minLength && $v.phone.phonePattern
                ? `Nomor telepon kamu terlalu pendek nih, panjang teks minimum ${$v.phone.$params.minLength.min}.`
                : null,
              !$v.phone.maxLength && $v.phone.phonePattern
                ? `Nomor telepon kamu terlalu panjang nih, panjang teks maksimum ${$v.phone.$params.maxLength.max}.`
                : null,
            ]"
            phonePrefix="true"
          />
        </div>
        <!-- Select Province -->
        <div class="col-md-4">
          <MyInputFormSelect
            id="province"
            label="Province *"
            v-model="$v.selectedProvince.$model"
            placeholder="Select Province *"
            :options="provinceList"
            :classComponent="{
              'is-invalid-custom': $v.selectedProvince.$error,
              'is-valid-custom': !$v.selectedProvince.$invalid,
            }"
            :invalidMessage="[
              !$v.selectedProvince.required ? 'Butuh provinsi kamu nih' : null,
            ]"
            @input="getDistrictList"
          />
        </div>
        <!-- Select District -->
        <div class="col-md-4">
          <MyInputFormSelect
            id="district"
            label="District *"
            v-model="$v.selectedDistrict.$model"
            placeholder="Select District *"
            :options="districtList"
            :classComponent="{
              'is-invalid-custom': $v.selectedDistrict.$error,
              'is-valid-custom': !$v.selectedDistrict.$invalid,
            }"
            :invalidMessage="[
              !$v.selectedDistrict.required ? 'Butuh daerah kamu nih' : null,
            ]"
            :disabled="districtList.length == 0"
            @input="getSubDistrictList"
          />
        </div>
        <!-- Select Sub District -->
        <div class="col-md-4">
          <MyInputFormSelect
            id="sub-district"
            label="Sub District *"
            v-model="$v.selectedSubDistrict.$model"
            placeholder="Select Sub District *"
            :options="subDistrictList"
            :classComponent="{
              'is-invalid-custom': $v.selectedSubDistrict.$error,
              'is-valid-custom': !$v.selectedSubDistrict.$invalid,
            }"
            :invalidMessage="[
              !$v.selectedSubDistrict.required
                ? 'Butuh kecamatan kamu kamu nih'
                : null,
            ]"
            :disabled="subDistrictList.length == 0"
          />
        </div>
      </div>
      <div class="row">
        <!-- Address -->
        <div class="col-8">
          <MyInputFormText
            id="address"
            label="Address *"
            v-model="$v.address.$model"
            :classComponent="{
              'is-invalid-custom': $v.address.$error,
              'is-valid-custom': !$v.address.$invalid,
            }"
            :invalidMessage="[
              !$v.address.required ? 'Butuh alamat kamu nih' : null,
            ]"
          />
        </div>
        <!-- Zip Code -->
        <div class="col-4">
          <MyInputFormText
            id="zip-code"
            type="text"
            label="Zip Code *"
            v-model="$v.zipCode.$model"
            :maxlength="5"
            :classComponent="{
              'is-invalid-custom': $v.zipCode.$error,
              'is-valid-custom': !$v.zipCode.$invalid,
            }"
            :invalidMessage="[
              !$v.zipCode.required ? 'Butuh kode postal kamu nih' : null,
              !$v.zipCode.numeric && $v.zipCode.required
                ? 'Masukan nomor yu!'
                : null,
              !$v.zipCode.minLength
                ? `Kode postal kamu terlalu pendek nih, panjang teks minimum ${$v.zipCode.$params.minLength.min}`
                : null,
            ]"
          />
        </div>
      </div>
      <div :class="{ 'text-right': textRight }">
        <div v-if="userData" class="form-group mb-3">
          <div class="custom-control custom-switch">
            <input
              type="checkbox"
              class="custom-control-input"
              id="main-address"
              v-model="mainAddress"
              :disabled="!hasMainAddress ? true : false"
            />
            <label class="custom-control-label" for="main-address"></label>
            <span style="font-size: 14px"> Set as Main Address </span>
          </div>
        </div>
        <button class="btn btn-wz-proceed" :class="{ 'btn-block': noCancel }">
          Save Address
        </button>
        <button
          v-if="!noCancel"
          type="button"
          @click="$emit('saveAddressResponse')"
          class="ml-2 d-inline btn btn-wz-proceed"
        >
          Cancel
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { toaster, regex } from "@/_helpers";
import { addressService } from "@/_services";
import MyInputFormText from "@/components/form/MyInputFormText";
import MyInputFormSelect from "@/components/form/MyInputFormSelect";
import {
  required,
  requiredIf,
  minLength,
  maxLength,
  numeric,
  helpers,
} from "vuelidate/lib/validators";

// Regex for Validation Start
const namePattern = helpers.regex("namePattern", regex.namePattern);
const phonePattern = helpers.regex("phonePattern", regex.phonePattern);
// Regex for Validation End

export default {
  props: ["hasMainAddress", "noCancel", "textRight", "parentData"],
  data() {
    return {
      email: null,
      full_name: null,
      phone: null,
      address: null,
      provinceList: [],
      selectedProvince: null,
      districtList: [],
      selectedDistrict: null,
      subDistrictList: [],
      selectedSubDistrict: null,
      zipCode: null,
      mainAddress: true,
    };
  },
  components: {
    MyInputFormText,
    MyInputFormSelect,
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
    apiKey() {
      return process.env.VUE_APP_API_KEY;
    },
    userData() {
      return this.$store.getters.getUserData;
    },
  },
  mounted() {
    this.getProvinceList();
    if (this.parentData) {
      this.setAddress(this.parentData);
    } else if (this.userData) {
      this.setUserData(this.userData);
    }
  },
  validations() {
    return {
      email: {
        required: requiredIf(() => !this.userData),
      },
      full_name: {
        required,
        namePattern,
      },
      phone: {
        required,
        minLength: minLength(9),
        maxLength: maxLength(14),
        phonePattern,
      },
      selectedProvince: {
        required,
      },
      selectedDistrict: {
        required,
      },
      selectedSubDistrict: {
        required,
      },
      zipCode: {
        required,
        numeric,
        minLength: minLength(5),
      },
      address: {
        required,
      },
    }
  },
  methods: {
    async saveAddress() {
      // Check Form Validation Start
      this.$v.$touch();
      if (this.$v.$invalid)
        toaster.make("Please fill the form correctly", "danger");
      // Check Form Validation End
      else {
        const phoneNumber = () => {
          if (this.phone.includes("+62")) {
            return this.phone.substring(3, this.phone.length);
          } else if (this.phone.charAt(0) == "0") {
            return this.phone.substring(1, this.phone.length);
          }
          return this.phone;
        };
        const body = {
          type: 1,
          name: this.full_name,
          address: this.address,
          phone: phoneNumber(),
          postal_code: parseInt(this.zipCode),
          is_primary: this.mainAddress,
          regions: parseInt(this.selectedProvince),
          districts: parseInt(this.selectedDistrict),
          city: parseInt(this.selectedSubDistrict),
        };

        // TODO for guest quick checkout
        if (!this.userData)
          return this.$emit('tempAddress', {
            name: body.name,
            address: body.address,
            city: this.subDistrictList.find(el => el.value == body.city).text,
            city_id: body.city,
            districts: this.districtList.find(el => el.value == body.districts).text,
            district_id: body.districts,
            regions: this.provinceList.find(el => el.value == body.regions).text,
            region_id: body.regions,
            is_primary: true,
            primary: true,
            phone: phoneNumber(),
            postal_code: body.postal_code,
            type: 1,
            guest: {
              email_quick_checkout: this.email,
              address_name: this.full_name,
              address_type: 1,
              address: this.address,
              address_phone: phoneNumber(),
              address_postal_code: parseInt(this.zipCode),
              address_is_primary: true,
              address_regions: parseInt(this.selectedProvince),
              address_districts: parseInt(this.selectedDistrict),
              address_city: parseInt(this.selectedSubDistrict),
              device_type: 'WEB',
              device_model: 'WEB',
              device_id: '',
            }
          })

        try {
          const res = !this.parentData
            ? await addressService.addAddress(body)
            : await addressService.editAddress(this.parentData.id, body);
          if (res.status === 200) {
            this.$emit("saveAddressResponse");
            toaster.make(res.data.message, "success");
          }
        } catch (e) {
          console.error(e);
          if (e.response) toaster.make(e.response.data.message, "danger");
        }
      }
    },
    async getProvinceList() {
      try {
        const res = await addressService.getProvinceList();
        if (res.status === 200) {
          const data = res.data.data;
          this.provinceList = data.map((el) => ({
            value: el.id,
            text: el.name,
          }));
        }
      } catch (e) {
        console.error(e);
      }
    },
    async getDistrictList(id, district = null, subDistrict = null) {
      this.selectedDistrict = district;
      this.selectedSubDistrict = subDistrict;
      this.districtList = [];
      this.subDistrictList = [];
      try {
        const res = await addressService.getDistrictList(id);
        if (res.status === 200) {
          const data = res.data.data;
          this.districtList = data.map((el) => ({
            value: el.id,
            text: el.name,
          }));
          if (district) this.getSubDistrictList(district, subDistrict);
        }
      } catch (e) {
        console.error(e);
      }
    },
    async getSubDistrictList(id, subDistrict = null) {
      this.selectedSubDistrict = subDistrict;
      this.subDistrictList = [];
      try {
        const res = await addressService.getSubDistrictList(id);
        if (res.status === 200) {
          const data = res.data.data;
          this.subDistrictList = data.map((el) => ({
            value: el.id,
            text: el.name,
          }));
        }
      } catch (e) {
        console.error(e);
      }
    },
    setAddress(data) {
      this.full_name = data.name;
      this.phone = data.phone;
      this.address = data.address;
      this.zipCode = data.postal_code;
      this.selectedProvince = data.region_id;
      this.getDistrictList(
        this.selectedProvince,
        data.district_id,
        data.city_id
      );
    },
    setUserData(data) {
      this.full_name = data.full_name;
      this.phone = /\+62/.test(data.phone)
        ? data.phone.replace(/\+62/, "")
        : data.phone;
    },
  },
  watch: {
    selectedProvince(newVal) {
      return parseInt(newVal);
    },
    selectedDistrict(newVal) {
      return parseInt(newVal);
    },
    selectedSubDistrict(newVal) {
      return parseInt(newVal);
    },
  },
};
</script>
