var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"save-address-form"}},[_c('h2',{staticClass:"mb-2",class:{ 'text-center title-address': _vm.textRight }},[(_vm.userData)?[_vm._v(" "+_vm._s(_vm.parentData ? "Edit" : "Add")+" address ")]:[_vm._v(" Buyer Details ")]],2),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveAddress.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[(!_vm.userData)?_c('div',{staticClass:"col-md-12"},[_c('MyInputFormText',{attrs:{"type":"email","id":"recipient-email","label":"Email *","placeholder":"Email","classComponent":{
            'is-invalid-custom': _vm.$v.email.$error,
            'is-valid-custom': !_vm.$v.email.$invalid,
          },"invalidMessage":[
            !_vm.$v.email.required ? 'Butuh email kamu nih' : null,
            !_vm.$v.email.namePattern ? 'Tolong diisi ya ;)' : null,
          ]},model:{value:(_vm.$v.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.email, "$model", $$v)},expression:"$v.email.$model"}})],1):_vm._e(),_c('div',{staticClass:"col-6"},[_c('MyInputFormText',{attrs:{"id":"recipient-name","label":"Recipient Name *","placeholder":"Recipient Name","classComponent":{
            'is-invalid-custom': _vm.$v.full_name.$error,
            'is-valid-custom': !_vm.$v.full_name.$invalid,
          },"invalidMessage":[
            !_vm.$v.full_name.required ? 'Butuh nama lengkap kamu nih' : null,
            !_vm.$v.full_name.namePattern ? 'Masukin text yu!' : null,
          ]},model:{value:(_vm.$v.full_name.$model),callback:function ($$v) {_vm.$set(_vm.$v.full_name, "$model", $$v)},expression:"$v.full_name.$model"}})],1),_c('div',{staticClass:"col-6"},[_c('MyInputFormText',{attrs:{"id":"phone-number","label":"Phone Number *","classComponent":{
            'is-invalid-custom': _vm.$v.phone.$error,
            'is-valid-custom': !_vm.$v.phone.$invalid,
          },"invalidMessage":[
            !_vm.$v.phone.required ? 'Butuh nomor telepon kamu nih' : null,
            !_vm.$v.phone.phonePattern
              ? 'Nomor telepon kamu tidak valid :('
              : null,
            !_vm.$v.phone.minLength && _vm.$v.phone.phonePattern
              ? `Nomor telepon kamu terlalu pendek nih, panjang teks minimum ${_vm.$v.phone.$params.minLength.min}.`
              : null,
            !_vm.$v.phone.maxLength && _vm.$v.phone.phonePattern
              ? `Nomor telepon kamu terlalu panjang nih, panjang teks maksimum ${_vm.$v.phone.$params.maxLength.max}.`
              : null,
          ],"phonePrefix":"true"},model:{value:(_vm.$v.phone.$model),callback:function ($$v) {_vm.$set(_vm.$v.phone, "$model", $$v)},expression:"$v.phone.$model"}})],1),_c('div',{staticClass:"col-md-4"},[_c('MyInputFormSelect',{attrs:{"id":"province","label":"Province *","placeholder":"Select Province *","options":_vm.provinceList,"classComponent":{
            'is-invalid-custom': _vm.$v.selectedProvince.$error,
            'is-valid-custom': !_vm.$v.selectedProvince.$invalid,
          },"invalidMessage":[
            !_vm.$v.selectedProvince.required ? 'Butuh provinsi kamu nih' : null,
          ]},on:{"input":_vm.getDistrictList},model:{value:(_vm.$v.selectedProvince.$model),callback:function ($$v) {_vm.$set(_vm.$v.selectedProvince, "$model", $$v)},expression:"$v.selectedProvince.$model"}})],1),_c('div',{staticClass:"col-md-4"},[_c('MyInputFormSelect',{attrs:{"id":"district","label":"District *","placeholder":"Select District *","options":_vm.districtList,"classComponent":{
            'is-invalid-custom': _vm.$v.selectedDistrict.$error,
            'is-valid-custom': !_vm.$v.selectedDistrict.$invalid,
          },"invalidMessage":[
            !_vm.$v.selectedDistrict.required ? 'Butuh daerah kamu nih' : null,
          ],"disabled":_vm.districtList.length == 0},on:{"input":_vm.getSubDistrictList},model:{value:(_vm.$v.selectedDistrict.$model),callback:function ($$v) {_vm.$set(_vm.$v.selectedDistrict, "$model", $$v)},expression:"$v.selectedDistrict.$model"}})],1),_c('div',{staticClass:"col-md-4"},[_c('MyInputFormSelect',{attrs:{"id":"sub-district","label":"Sub District *","placeholder":"Select Sub District *","options":_vm.subDistrictList,"classComponent":{
            'is-invalid-custom': _vm.$v.selectedSubDistrict.$error,
            'is-valid-custom': !_vm.$v.selectedSubDistrict.$invalid,
          },"invalidMessage":[
            !_vm.$v.selectedSubDistrict.required
              ? 'Butuh kecamatan kamu kamu nih'
              : null,
          ],"disabled":_vm.subDistrictList.length == 0},model:{value:(_vm.$v.selectedSubDistrict.$model),callback:function ($$v) {_vm.$set(_vm.$v.selectedSubDistrict, "$model", $$v)},expression:"$v.selectedSubDistrict.$model"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-8"},[_c('MyInputFormText',{attrs:{"id":"address","label":"Address *","classComponent":{
            'is-invalid-custom': _vm.$v.address.$error,
            'is-valid-custom': !_vm.$v.address.$invalid,
          },"invalidMessage":[
            !_vm.$v.address.required ? 'Butuh alamat kamu nih' : null,
          ]},model:{value:(_vm.$v.address.$model),callback:function ($$v) {_vm.$set(_vm.$v.address, "$model", $$v)},expression:"$v.address.$model"}})],1),_c('div',{staticClass:"col-4"},[_c('MyInputFormText',{attrs:{"id":"zip-code","type":"text","label":"Zip Code *","maxlength":5,"classComponent":{
            'is-invalid-custom': _vm.$v.zipCode.$error,
            'is-valid-custom': !_vm.$v.zipCode.$invalid,
          },"invalidMessage":[
            !_vm.$v.zipCode.required ? 'Butuh kode postal kamu nih' : null,
            !_vm.$v.zipCode.numeric && _vm.$v.zipCode.required
              ? 'Masukan nomor yu!'
              : null,
            !_vm.$v.zipCode.minLength
              ? `Kode postal kamu terlalu pendek nih, panjang teks minimum ${_vm.$v.zipCode.$params.minLength.min}`
              : null,
          ]},model:{value:(_vm.$v.zipCode.$model),callback:function ($$v) {_vm.$set(_vm.$v.zipCode, "$model", $$v)},expression:"$v.zipCode.$model"}})],1)]),_c('div',{class:{ 'text-right': _vm.textRight }},[(_vm.userData)?_c('div',{staticClass:"form-group mb-3"},[_c('div',{staticClass:"custom-control custom-switch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.mainAddress),expression:"mainAddress"}],staticClass:"custom-control-input",attrs:{"type":"checkbox","id":"main-address","disabled":!_vm.hasMainAddress ? true : false},domProps:{"checked":Array.isArray(_vm.mainAddress)?_vm._i(_vm.mainAddress,null)>-1:(_vm.mainAddress)},on:{"change":function($event){var $$a=_vm.mainAddress,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.mainAddress=$$a.concat([$$v]))}else{$$i>-1&&(_vm.mainAddress=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.mainAddress=$$c}}}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":"main-address"}}),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(" Set as Main Address ")])])]):_vm._e(),_c('button',{staticClass:"btn btn-wz-proceed",class:{ 'btn-block': _vm.noCancel }},[_vm._v(" Save Address ")]),(!_vm.noCancel)?_c('button',{staticClass:"ml-2 d-inline btn btn-wz-proceed",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('saveAddressResponse')}}},[_vm._v(" Cancel ")]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }